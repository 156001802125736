.walkthrough_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 32;
}

.hide_component {
  display: none;
}

.zIndexForComponent {
  z-index: 50 !important;
  position: relative;
  pointer-events: none;
}

.editor {
  width: 100%;
  align-items: center;
  padding: 1.5em 4vw;

  // Container which has all the editors
  .editor__codeArea {
    width: 100%;
    min-height: 72vh;
    display: flex;
    gap: 2rem;
    margin: 1em 0;
    margin-top: 1.5em;
    justify-content: space-between;

    // Left side container [children: question, feedback]
    .editor__leftPanel {
      width: 40.5%;
      min-height: 72vh;
      min-width: 382px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .CodeMirror{
        border-color: #0C5BF3;
      }
      & .textEditor:first-child {
        width: 100%;
        height: 53%;
      }

      & .textEditor:last-child {
        width: 100%;
        height: 100%;
      }
    }

    // Right Side Container [has work area]
    & > div:last-child {
      width: 56%;
      min-height: 72vh;
      .CodeMirror{
        border-color: #F34956;
      }
    }

    @media screen and (max-width: 768px), screen and (max-height: 620px) {
      & {
        flex-wrap: wrap;

        .editor__leftPanel {
          width: 100%;
          min-height: 90vh;
        }

        & > div:last-child {
          width: 100%;
        }
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    & {
      padding-top: 1em;
    }
  }
}

/* Firepad, Code Mirror Styles */
.firepad {
  width: 100%;
  height: 100%;
}

.firepad .CodeMirror,
.CodeMirror {
  background: #272822 !important;
  font-family: CircularStd, Overpass Mono, monospace !important;
  font-size: 16px !important;
  color: #fff;
  border-radius: 10px;
  top: 45px;
  padding-bottom: 1rem;
  border-top: 2px solid;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  @media screen and (max-width:1024px){
    top: 65px;
  }
}



.CodeMirror-placeholder{
  color: #ffffff77 !important;
}

@media screen and (min-width: 1560px) {
  .firepad .CodeMirror,
  .CodeMirror {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1347px) and (min-width: 1024px) {
  .firepad .CodeMirror,
  .CodeMirror {
    top: 66px;
  }
}

@media screen and (max-width: 768px) {
  .codeEditor__container .firepad .CodeMirror,
  .codeEditor__container .CodeMirror {
    top: 66px;
  }
}

@media screen and (max-width: 568px) {
  .textEditor .firepad .CodeMirror,
  .textEditor .CodeMirror {
    top: 66px;
  }
}

.CodeMirror-scroll {
  padding-bottom: 60px !important;
}

.CodeMirror-scrollbar-filler {
  display: none !important;
}

.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar {
  outline: none;
}

.CodeMirror-vscrollbar::-webkit-scrollbar {
  background: transparent;
  width: 6px;
}

.CodeMirror-hscrollbar::-webkit-scrollbar {
  background: transparent;
  height: 6px;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-track,
.CodeMirror-hscrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-thumb {
  background: #bcd;
  border-radius: 6px;
  min-width: 1px;
  width: 6px;
}

.CodeMirror-hscrollbar::-webkit-scrollbar-thumb {
  background: #bcd;
  border-radius: 6px;
  min-height: 1px;
  height: 6px;
}

.firepad .CodeMirror-gutters {
  background: transparent !important;
  border-right: none;
}

.firepad .CodeMirror-linenumber {
  color: #ffffff4b !important;
  font-weight: 500;
  text-align: center;
}

.CodeMirror-cursor {
  border-left: 2px solid #fff;
}

.powered-by-firepad {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.firepad-toolbar {
  padding: 0;
  height: fit-content;
  border: none;
  margin-top: 0.4em;
}

.firepad-toolbar .firepad-toolbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
}

@media screen and (min-width: 1560px), screen and (max-height: 620px) {
  .firepad-toolbar .firepad-toolbar-wrapper {
    max-width: 650px;
  }
}

@media screen and (max-width: 1350px) {
  .firepad-toolbar .firepad-toolbar-wrapper {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 426px) {
  .firepad-toolbar .firepad-toolbar-wrapper {
    margin-right: 20%;
  }
}

/* Changing the width for the toolbar for the codeEditor */
.codeEditor .firepad-toolbar .firepad-toolbar-wrapper {
  width: 72%;
}

@media screen and (min-width: 1560px) {
  .codeEditor .firepad-toolbar .firepad-toolbar-wrapper {
    width: 74%;
  }
}

.firepad-toolbar .firepad-btn-group {
  margin: 0;
}

.firepad-toolbar a.firepad-btn {
  background: #272822 !important;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: 400;
  border: none;
}

@media screen and (min-width: 1560px) {
  .firepad-toolbar a.firepad-btn {
    font-size: 15px;
    padding: 9px 10px;
  }
}

@media screen and (max-width: 1350px) {
  .firepad-toolbar a.firepad-btn {
    font-size: 13px;
    padding: 6px 8px;
  }

  .firepad-toolbar .firepad-btn-group {
    margin: 2px;
  }
}

/* Hiding the font style changing block */
.firepad-btn-group:first-child {
  display: none;
}

/* Hiding the color block */
.firepad-btn-group:nth-child(3) {
  display: none;
}

/* Styles for insert image modal */
.firepad-dialog {
  background: rgba(0, 0, 0, 0.7);
}

.firepad-dialog-div {
  background: #373a32;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.firepad-dialog-div .firepad-dialog-input {
  width: 100%;
  border-radius: 6px;
  background: #272822;
  color: #fff;
  font-size: 14px;
  font-family: CircularStd, Poppins, sans-serif;
  font-weight: 300;
  margin: 0.8em 0;
  border: none;
  outline: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 8px 10px;
}

@media screen and (min-width: 1560px) {
  .firepad-dialog-div .firepad-dialog-input {
    font-size: 15px;
  }
}

.firepad-dialog-div .firepad-dialog-input::placeholder {
  font-weight: 400;
}

.firepad-dialog-div a.firepad-btn {
  border: none;
  color: #fff;
  background: #0099ff;
  box-shadow: none;
  border-radius: 5px;
  font-size: 14px;
}

.firepad-dialog-div a.firepad-btn:last-child {
  background: #eb5757;
  margin-left: 1em;
}

@media screen and (min-width: 1560px) {
  .firepad-dialog-div a.firepad-btn {
    font-size: 15px;
  }
}

/* font size dropdown styles` */
.firepad-dropdown-menu {
  background: #373a32;
  padding: 0;
}

.firepad-dropdown-menu a {
  color: #fff;
  padding: 8px 1.5em;
  border-bottom: 1px solid rgb(43, 43, 43);
  line-height: 1 !important;
  text-align: center;
}

@media screen and (min-width: 1560px) {
  .firepad-dropdown-menu a {
    padding: 9px 1.5em;
  }
}

.firepad-dropdown-menu a:last-child {
  border-bottom: none;
}

.firepad-dropdown-menu a span {
  padding: 0;
  font-size: 14px !important;
  line-height: 1 !important;
}

@media screen and (min-width: 1560px) {
  .firepad-dropdown-menu a span {
    font-size: 16px !important;
  }
}


.iframe-view.editor{
  height: calc(100% - 2em);
  padding: 0 4vw;
  .editor__codeArea{
    margin: 0px;
    height:100%;
    .editor__leftPanel{
      min-width: unset;
    }
    .codeEditor__container{
      margin-top: 0;
    }
  }
}
