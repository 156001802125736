.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: CircularStd, Poppins, sans-serif;
  font-size: 1em;
  padding: 1em 0;
  margin: 0 8vw;
  margin-top: 0 !important;
  border-top: 1px solid rgba(255, 255, 255, 0.3);

  a {
    text-decoration: none;
    color: inherit;
  }

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  & > div:first-child {
    flex: 1;
    color: #45adff;

    & > a:first-child {
      padding-right: 1em;
    }

    & > a:last-child {
      padding-left: 1em;
      border-left: 1px solid rgba(255, 255, 255, 0.8);
    }
  }

  & > div:last-child {
    color: rgba(255, 255, 255, 0.8);

    img {
      width: 18px;
      margin: 0 8px;
    }

    a {
      color: #45adff;
      margin-right: 10px;
    }
  }

  @media screen and (min-width: 1560px) {
    & {
      font-size: 1.5em;

      & > div:last-child {
        img {
          width: 32px;
          margin: 0 12px;
        }

        span {
          margin-right: 12px;
        }
      }
    }
  }

  @media screen and (max-width: 426px) {
    & {
      margin-top: 1.4em;
      font-size: 0.6em;

      & > div:first-child {
        & > span:first-child {
          padding-right: 0.5em;
        }

        & > span:last-child {
          padding-left: 0.5em;
        }
      }

      & > div:last-child {
        img {
          width: 14px;
          margin: 0 4px;
        }

        span {
          margin-right: 4px;
        }
      }
    }
  }
}
