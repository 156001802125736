.documentNameEditor {
  position: relative;
  display: flex;
  align-items: center;

  .documentNameEditor__preplacedLogo {
    width: 28px;
    margin-right: 0.9em;
  }

  .documentNameEditor__input {
    outline: none;
    border: 1.8px solid transparent;
    padding: 0.58em 1em;
    padding-right: 2.6em;
    background: #23262b;
    border-radius: 12px;
    font-family: CircularStd, Poppins, sans-serif;
    font-size: 1em;
    color: rgba(255, 255, 255, 0.7);

    &:focus {
      color: #fff;
      border: 1.8px solid #0099ff;
    }

    &::placeholder {
      font-size: 0.85em;
      font-weight: 500;
    }
  }

  .MuiIconButton-root {
    position: absolute;
    cursor: pointer;
    // pointer-events: none;
    top: 50%;
    right: 4%;
    padding: 6px;
    color: #fff;
    transform: translateY(-50%);

    .MuiSvgIcon-root {
      font-size: 14px;
    }
  }
}
