.landing {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #fff;
}

.landing__heroText {
  padding: 1.8em 0;
  text-align: center;
}

.landing__heroText h1 {
  font-weight: 500;
  font-size: 3.6em;
  margin-top: 0.6em !important;
  word-wrap: break-word;
}

.landing__heroText h3 {
  color: rgba(255, 255, 255, 0.65);
  font-size: 1.6em;
  font-weight: 400;
  margin-top: 1em !important;
}

.landing__heroTextFeatures {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing__heroTextFeature {
  display: flex;
  align-items: center;
  font-size: 1.6em;
  margin: 2.8em 5%;
}

.landing__heroTextFeature .MuiSvgIcon-root {
  font-size: 1.4em;
  color: #1d70f0;
  margin-right: 0.4em;
}

.start-button {
  &.MuiButton-root {
    background: linear-gradient(to left, #0098fe, #2364eb);
    color: #fff;
    border-radius: 10px;
    text-transform: capitalize;
    margin: 3em 0em;
    padding: 0.5em 2em;
    font-weight: 500;
    font-size: 1.4em;
    font-family: CircularStd;
  }
}

.preview_container {
  text-align: center;
}

.editor-preview {
  width: 798px;
}

.used_by {
  text-align: center;
  margin: 10em 10% 5em 10%;
  p {
    color: white;
    font-size: 2em;
    margin-bottom: 2em;
  }
  .companies {
    margin-top: 3em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .company_logo {
      margin: 2em 0em;
      flex: 1 0 21%;
      img {
        width: 8em;
      }
      .microsoft,
      .walmart,
      .facebook {
        width: 11em;
      }
    }
  }
}

@media screen and (max-width: 2560px) {
  .landing {
    justify-content: space-between;
  }

  .landing__heroText {
    padding: 3em 0;
  }

  .landing__heroText h1 {
    font-size: 4.8em;
  }

  .landing__heroText h3 {
    font-size: 2em;
    margin-top: 0.6em;
  }

  .landing__heroTextFeature {
    font-size: 2em;
    margin: 2.4em 7%;
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 1600px) {
  .landing {
    justify-content: center;
  }

  .landing__heroText {
    padding: 0.8em 0;
  }

  .landing__heroText h1 {
    font-size: 2.8em;
  }

  .landing__heroText h3 {
    font-size: 1.2em;
    margin-top: 0.4em;
  }

  .landing__heroTextFeature {
    font-size: 1.2em;
    margin: 2em 7%;
    margin-bottom: 1.4em;
  }
}

@media screen and (max-width: 1024px) {
  .landing__heroText {
    padding: 0.2em 0;
  }

  .landing__heroText h1 {
    font-size: 2.4em;
  }

  .landing__heroText h3 {
    font-size: 1em;
    margin-top: 0.4em;
  }

  .landing__heroTextFeature {
    font-size: 1em;
    margin: 2.8em 6%;
  }

  .editor-preview {
    width: 698px;
  }

  .used_by {
    .companies {
      .company_logo {
        img {
          width: 7em;
        }
        .microsoft,
        .walmart,
        .facebook {
          width: 10em;
        }
      }
    }
  }
}

@media screen and (max-width: 868px) {
  .landing__heroText {
    padding: 1.4em 4vw;
  }

  .landing__heroText h1 {
    font-size: 1.9em;
  }

  .landing__heroText h3 {
    font-size: 0.95em;
    margin-top: 0.6em;
  }

  .landing__heroTextFeatures {
    margin: 0 4vw;
    flex-wrap: wrap;
  }

  .landing__heroTextFeature {
    font-size: 0.9em;
    margin: 2.8em 4%;
    margin-bottom: 1em;
  }
  .editor-preview {
    width: 598px;
  }
  .used_by {
    .companies {
      margin-top: 0;
      .company_logo {
        flex: 0 0 33.333333%;
      }
    }
  }
}

@media screen and (max-width: 698px) {
  .start-button {
    margin: 2.2em 0em !important;
    padding: 0.35em 0em !important;
    width: 250px;
    font-size: 1.2em !important;
  }

  .editor-preview {
    width: 90%;
  }

  .used_by {
    // text-align: center;
    margin-top: 6em;
    p {
      font-size: 1.6em;
      margin-bottom: 1em;
    }
    .companies {
      flex-direction: column;
      margin-top: 0;
      .company_logo {
        margin: 1.5em 0em;
        flex: 1;
        img {
          width: 5.6em;
        }
        .microsoft,
        .walmart,
        .facebook {
          width: 7.6em;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .landing__heroText {
    padding: 1em 0;
  }

  .landing__heroText h1 {
    font-size: 1.6em;
  }

  .landing__heroText h3 {
    font-size: 0.6em;
    margin: 0 2%;
    margin-top: 0.8em;
  }

  .landing__heroTextFeatures {
    flex-wrap: wrap;
    margin: 1.6em 0;
    margin-bottom: 0;
  }

  .landing__heroTextFeature {
    font-size: 0.8em;
    margin: 1.2em 3%;
  }

  .start-button {
    font-size: 1em !important;
    width: 220px;
  }

  .used_by {
    margin: 4em 0;
    p {
      font-size: 1.6em;
    }
    .companies {
      .company_logo {
        margin: 1em 0em;
        flex: 1;
        img {
          width: 5em;
        }
        .microsoft,
        .walmart,
        .facebook {
          width: 7.5em;
        }
      }
    }
  }
}
