.sectionDropdown__sectionDropdownContainer {
  position: relative;
  margin-left: auto;
  margin-right: 3em;
  color: #fff;

  .dropdownBox__backgroundFrame {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(26, 28, 30, 0.8);
    z-index: 15;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    animation: fadeOut 0.2s linear;

    &.dropdownBox__backgroundFrame--open {
      pointer-events: auto;
      user-select: auto;
      opacity: 1;
      animation: fadeIn 0.2s linear;
    }
  }

  .sectionDropdown__currentSectionWrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #23262b;
    border-radius: 12px;
    padding-right: 0;
    width: 450px;
    height: 40px;
    font-size: 15px;
    font-family: CircularStd, Poppins, sans-serif;
    color: rgba(255, 255, 255, 0.85);
    font-weight: 400;
    z-index: 20;
    transition: all 0.1s linear;

    &.sectionDropdown__currentSectionWrapper--open {
      & .MuiButton-root:last-child {
        border-bottom-right-radius: 0;

        .MuiSvgIcon-root {
          transform: rotate(180deg);
        }
      }
    }

    .custom-size-dropdown {
      font-size: 1.5em !important;
    }

    // Current section name
    .sectionDropdown__currentSectionName {
      outline: none;
      cursor: pointer;
      flex: 1;
      max-width: 355px;
      height: 40px;
      color: rgba(255, 255, 255, 0.7);
      text-align: left;
      padding: 0.75em 2em;
      font-size: 1em;
      font-family: CircularStd, Poppins, sans-serif;
      background: transparent;
      border: 1.8px solid transparent;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;

      &:focus {
        color: #fff;
        border: 1.8px solid #0099ff;
      }

      &::placeholder {
        font-size: 0.85em;
        font-weight: 500;
      }
    }

    // Container for edit button and down arrow
    .sectionDropdown__sectionOptions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .MuiIconButton-root {
        padding: 6px;
        color: #fff;
      }

      .MuiButton-root {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        height: 40px;
        min-width: 38px;
        padding: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        color: #fff;
      }

      & .MuiIconButton-root:first-child {
        margin-right: 0.6em;
      }

      .MuiSvgIcon-root {
        font-size: 14px;
        transition: all 0.1s linear;
      }
    }
  }

  .sectionDropdown__dropdownBox {
    position: absolute;
    top: 103%;
    left: -1px;
    right: 0;
    width: 450px;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    animation: fadeOut 0.2s linear;
    padding: 1em;
    background-color: #23262b;
    border-radius: 12px;

    &.sectionDropdown__dropdownBox--open {
      opacity: 1;
      pointer-events: auto;
      user-select: auto;
      animation: fadeIn 0.2s linear;
      margin-top: 10px;
    }

    .dropdownBox__item {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-family: CircularStd, Poppins, sans-serif;
      font-size: 13px;
      color: rgba(255, 255, 255, 0.85);
      font-weight: 400;
      border-top: none;
      min-height: 20px;
      line-height: 1;
      justify-content: center;
      padding: 10px 0;
      text-transform: capitalize;
      padding: 0.6em 1.2em;
      margin: 0.5em 0 0.5em 0;

      & > div {
        flex: 1;
        text-align: left;
        cursor: pointer;
      }

      &.dropdownBox__item--disabled {
        background: #363940;
        color: rgba(255, 255, 255, 0.4);
        border-radius: 7.62px;
      }

      .MuiIconButton-root {
        position: absolute;
        top: 50%;
        right: 2%;
        padding: 5px;
        margin-left: 0.8em;
        box-shadow: none;
        z-index: 30;
        transform: translateY(-50%);

        .MuiSvgIcon-root {
          margin: 0;
        }
      }

      .MuiSvgIcon-root {
        color: #fff;
        margin-left: 0.5em;
        font-size: 18px;
      }
    }

    & > .dropdownBox__item:first-child {
      margin-top: 0em;
    }

    & > .dropdownBox__item:last-child {
      text-align: center;
      margin: 2em auto;
      text-transform: none;
      margin-bottom: 0em;
      width: 14em;
      border-radius: 254px;
      background: #2563eb;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @media screen and (min-width: 1560px) {
    & {
      .sectionDropdown__currentSectionWrapper {
        width: 520px;
        height: 42px;
        font-size: 16px;

        .sectionDropdown__currentSectionName {
          max-width: 420px;
        }

        .sectionDropdown__sectionOptions {
          .MuiButton-root {
            height: 42px;
          }

          .MuiSvgIcon-root {
            font-size: 18px;
          }
        }
      }

      .sectionDropdown__dropdownBox {
        width: 520px;

        .dropdownBox__item {
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    & {
      margin: 2em 0;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 426px) {
    & {
      margin: 3em auto;
      margin-bottom: 1em;

      .sectionDropdown__dropdownBox {
        width: 100.5%;
      }
    }
  }
}
