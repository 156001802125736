.editorCardsPreview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5em 4vw;

  & > * {
    width: 100%;
  }

  .editorCardsPreview__header {
    display: flex;
    align-items: center;
    height: 6vh;

    .header__leftSidePreview {
      display: flex;
      align-items: center;

      .header__docNamePreview {
        margin-left: 1.4em;
      }
    }

    .header__rightSidePreview {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: auto;

      & > span:not(:last-child) {
        margin-right: 2em;
      }
    }
  }

  .editorCardsPreview__content {
    width: 100%;
    min-height: 72vh;
    display: flex;
    margin: 1em 0;
    margin-top: 2.4em;
    justify-content: space-between;

    .content__leftSide {
      width: 40.5%;
      min-height: 72vh;
      min-width: 382px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .leftSide__preview {
        width: 100%;
        height: 53%;
      }

      .rightSide__preview {
        width: 100%;
        height: 40%;
      }
    }

    .content__rightSide {
      width: 56%;
      min-height: 72vh;
    }
  }

  .MuiSkeleton-root {
    background: rgba(255, 255, 255, 0.125);
    transform: scale(1, 1);
  }

  @media screen and (min-width: 1560px) {
    & {
      .editorCardsPreview__header {
        .header__logoPreview > span {
          width: 80px !important;
          height: 80px !important;
        }

        .header__docNamePreview > span {
          width: 300px !important;
          height: 68px !important;
        }

        .header__rightSidePreview > span {
          height: 68px !important;
        }

        .header__rightSidePreview > span:first-child {
          width: 540px !important;
        }
        .header__rightSidePreview > span:not(:first-child) {
          width: 180px !important;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    & {
      .editorCardsPreview__header {
        flex-wrap: wrap;
        height: fit-content;

        .header__rightSidePreview {
          margin-left: 0;
          margin-top: 2em;
        }
      }

      .editorCardsPreview__content {
        flex-wrap: wrap;

        .content__leftSide,
        .content__rightSide {
          width: 100%;
        }

        .content__rightSide {
          margin-top: 2em;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    & {
      .editorCardsPreview__header {
        .header__rightSidePreview {
          & > span:first-child {
            width: 80vw !important;
            margin: 0;
          }

          & > span:not(:first-child) {
            display: none;
          }
        }
      }
    }
  }
}
