.card__form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-width: 540px;
  height: fit-content;
  color: #fff;
  background: #272822;
  padding: 2.4em 2.6em;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.card__formTitle {
  text-align: center;
  font-weight: 500;
  opacity: 0.9;
}

.card__message {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.55);
  margin-top: 0.8em;
  margin-bottom: 2em;
  text-align: center;
}

.card__sectionHeading {
  font-size: 15px;
  margin-bottom: 1em;
  color: rgba(255, 255, 255, 0.8);
}

.card__inputContainer {
  position: relative;
  margin-top: 0.75em;
  margin-bottom: 1.2em;
}

.card__input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: #171717;
  margin: 0.6em 0;
  font-family: CircularStd, Poppins, sans-serif;
  font-size: 1em;
  color: rgba(255, 255, 255, 0.9);
  padding: 1.2em 1.4em;
  letter-spacing: 12px;
  border-radius: 8px;
}

.card__input::placeholder {
  color: rgba(255, 255, 255, 0.45);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.MuiIconButton-root.card__toggleHide {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  top: 36%;
  right: 2%;
  color: rgba(255, 255, 255, 0.85);
}

.MuiButton-root.card__submit {
  width: fit-content;
  padding: 0.6em 2.6em;
  margin-left: auto;
  color: #fff;
  background: #0099ff;
  box-shadow: none;
  font-family: CircularStd, Poppins, sans-serif;
  font-size: 1em;
  font-weight: 400;
  text-transform: none;
}

.MuiButton-root.card__submit:hover {
  background: #0099ff;
}

.card__toggleContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1em;
  margin-bottom: 1.2em;
}

.card__toggleCheckbox {
  display: none;
}

.card__toggleSliderContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgba(255, 255, 255, 0.85);
  font-size: 13px;
}

.card__toggleSliderContainer *:not(:last-child) {
  margin-right: 0.8em;
}

.card__toggleSliderContainer span {
  padding: 3px 5px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.7);
  transition: background 0.3s ease;
}

.card__toggleSliderContainer span.card__togglePackageHighlight {
  background: rgba(7, 24, 41, 0.6);
}

.card__toggleLabel {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  background: rgba(241, 132, 6, 0.3);
  border-radius: 5em;
  padding: 3px;
}

.card__toggleLabel::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 100%;
  background: rgba(241, 132, 6, 1);
  height: 20px;
  width: 20px;
  transform: translate(30%, -50%);
  transition: all 0.24s ease-in;
}

.card__toggleCheckbox:checked + .card__toggleLabel {
  background: hsl(160, 77%, 17%);
}

.card__toggleCheckbox:checked + .card__toggleLabel::after {
  transform: translate(162%, -50%);
  background: hsl(162, 80%, 42%);
}

.card__toggleDocTypeInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 13.5px;
  margin-top: 0.8em;
  color: rgba(255, 255, 255, 0.75);
  background: rgba(7, 24, 41, 0.6);
  padding: 10px;
  border-radius: 5px;
  line-height: 1.8;
}

@media screen and (max-width: 768px) {
  .card__form {
    width: 85vw;
    min-width: 300px;
  }
}

@media screen and (max-width: 426px) {
  .card__form {
    padding: 2em 1.4em;
  }

  .card__formTitle {
    font-size: 1.6em;
  }

  .card__message {
    font-size: 13px;
  }

  .card__sectionHeading {
    font-size: 13px;
  }

  .card__inputContainer {
    margin-top: 1em;
  }

  .card__input {
    padding: 1.65em 1.4em;
    font-size: 0.8em;
    letter-spacing: 10px;
  }

  .MuiIconButton-root.card__toggleHide .MuiSvgIcon-root {
    font-size: 0.85em;
  }

  .MuiButton-root.card__submit {
    width: 100%;
    padding: 0.5em 2.2em;
    font-size: 0.9em;
    margin-top: 1em;
  }
}
