.header {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;

  .header__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 2em;
    }

    .MuiButton-root.header__copyButton {
      background: #0099ff;
      color: #fff;

      .MuiSvgIcon-root {
        margin-right: 0.4em;
      }
    }

    .MuiButton-root.header__endButton {
      background: transparent;
      color: #eb5757;
      border: 1px solid #eb5757;
    }

    .MuiButton-root.header__loginButton {
      background: #2563eb;
      color: white;
      width: 9em;
    }
  }

  .MuiButton-root {
    text-transform: none;
    font-family: CircularStd, Poppins, sans-serif;
    font-weight: 300 !important;
    padding: 0.45em 0.9em;

    .MuiSvgIcon-root {
      font-size: 1.2em;
    }
  }

  // Hamburger Icon
  .MuiIconButton-root.header__hamIconButton {
    position: absolute;
    right: 1%;
    top: 50%;
    display: none;
    top: 10%;
    right: 0;
    color: #fff;
    padding: 5px;
    z-index: 1300;
    transform: translateY(-50%);
  }

  // Loading animation
  .loading_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading_animation {
      width: 15em;
      height: 0.8em;
      border: 1px solid grey;
      border-radius: 8em;
      position: relative;
      overflow: hidden;
    }

    .loading_animation::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: loading 1.2s ease-in-out infinite alternate;
      background-color: #0099ff;
    }

    @keyframes loading {
      from {
        transform: translateX(-100%);
      }
      to {
        transform: translateX(100%);
      }
    }
  }

  .header__loginPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(26, 28, 30, 0.8);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 1560px) {
    & {
      .MuiButton-root {
        font-size: 1.2em;
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    & {
      margin-bottom: 1em;
    }
  }

  @media screen and (max-width: 1330px) {
    & {
      align-items: flex-start;
      margin-bottom: 0;

      .MuiIconButton-root.header__hamIconButton {
        display: block;
        top:50%;
      }

      .header__buttons {
        & > * {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    & {
      flex-direction: column;

      .MuiIconButton-root.header__hamIconButton {
        top:0%;
        transform: none;
      }
    }
  }

  @media screen and (max-width: 426px) {
    & {
      .header__buttons {
        width: 100%;
      }
    }
  }
}
