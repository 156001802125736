.preplacedLogo {
  a {
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
  }

  img {
    width: 28px;
    margin-right: 0.9em;
  }

  span {
    font-size: 1.4em;
    font-weight: 500;
  }

  @media screen and (min-width: 1560px) {
    & {
      img {
        width: 42px;
        margin-right: 1em;
      }

      span {
        font-size: 1.6em;
        font-weight: 500;
      }
    }
  }

  @media screen and (max-width: 1560px) {
    & {
      img {
        width: 28px;
      }

      span {
        font-size: 1.2em;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    & {
      img {
        width: 24px;
      }

      span {
        font-size: 1.1em;
      }
    }
  }

  @media screen and (max-width: 426px) {
    & {
      img {
        width: 22px;
        margin-right: 0.4em;
      }

      span {
        font-size: 0.9em;
        font-weight: 500;
      }
    }
  }
}
