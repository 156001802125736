.tooltip_container{
    // width: 24em;
    width: 18em;
    display: flex;
    justify-content: center;
    align-items: center; 
    position: absolute;
    z-index: 56;
    .tooltip{
        background-color: #363940;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        .tooltip_title{
            display: flex;
            justify-content: space-between;
            background-color: #23262B;
            padding: 0.6em 1.4em;
            font-size: 1.03em;
            align-items: center;
            font-family: CircularStd, Poppins, sans-serif;
            p{
                color: white;
            }
            .MuiSvgIcon-root{
                fill: white;
                cursor: pointer;
                font-size: 1.03em;
            }
        }
        .tooltip_body{
            padding: 1.4em;
            color: white;
            line-height: 1.5em;
            font-size: 0.9em;
            font-weight: 400;
            font-family: CircularStd, Poppins, sans-serif;
        }
        .tooltip_buttons{
            padding: 0.5em 1.8em 1.5em 1.8em;
            text-align: right;
            .tooltip_next_btn, .tooltip_skip_btn{
                background: #2563EB;
                color: white;
                border: 0;
                border-radius: 0.2em;
                font-size: 0.9em;
                width: 57px;
                height: 27px;
                cursor: pointer;
                font-family: CircularStd, Poppins, sans-serif;
            }
            .tooltip_skip_btn{
                background: transparent;
                margin-left: 0.2em;
            }
        }
    }
}

.tooltip_bottom{
    flex-direction: column;
    .tooltip_arrow{
        width: 0; 
        height: 0; 
        border-left: 1.2em solid transparent;
        border-right: 1.2em solid transparent;
        border-bottom: 1.2em solid #23262B;
    }
}

.tooltip_top{
    flex-direction: column-reverse; 
    .tooltip_arrow{
        width: 0; 
        height: 0; 
        border-left: 1.2em solid transparent;
        border-right: 1.2em solid transparent;
        border-top: 1.2em solid #23262B;
      }
}

.tooltip_left{
    flex-direction: row-reverse;
    .tooltip_arrow{
        width: 0; 
        height: 0; 
        border-top: 1.2em solid transparent;
        border-bottom: 1.2em solid transparent;
        border-left: 1.2em solid #363940;
      }
}

.tooltip_right{
    flex-direction: row;
    .tooltip_arrow{
        width: 0; 
        height: 0; 
        border-top: 1.2em solid transparent;
        border-bottom: 1.2em solid transparent;
        border-right: 1.2em solid #363940;
      }
}

.tooltip_center{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .tooltip-arrow{
        display: none;
    }
}
