.resetPasswordCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 460px;
    height: fit-content;
    color: #fff;
    background: #272822;
    padding: 2.4em 2.6em;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

    .resetPasswordCard__heading {
        font-size: 26px;
        text-align: center;
        margin-bottom: 0.5em;
        color: rgba(255, 255, 255, 0.95);
    }

    form {
        display: flex;
        flex-direction: column;

        input {
        width: 100%;
        border: none;
        outline: none;
        background: #171717;
        font-family: CircularStd, Poppins, sans-serif;
        font-size: 1em;
        color: rgba(255, 255, 255, 0.9);
        padding: 1.2em 1.4em;
        margin: 0.6em 0;
        border-radius: 8px;

            &::placeholder {
                color: rgba(255, 255, 255, 0.45);
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.5px;
            }
        }
        .passwordResetError{
            margin: 1em 0em;
            text-align: center;
        }
        .MuiButton-root {
            width: 100%;
            color: #fff;
            text-transform: none;
            padding: 1em 2em;
            font-weight: 400;
            font-size: 1em;
            font-family: CircularStd, Poppins, sans-serif;
        
            &.changePasswordBtn {
              margin-top:0.75em;
              background: #ff4181;
        
              &:hover {
                background: #ff4181;
              }
            }   
        }
    }
}