@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Overpass+Mono:wght@300;400;600;700&display=swap");

.app {
  height: inherit;
  position: relative;
  display: grid;
  place-items: center;
  font-family: CircularStd, Poppins, sans-serif;
  background: #121212;
}

/* Start Styles for Modal Which opens end Interview spinner */
.MuiCircularProgress-root {
  position: absolute;
  top: 46%;
  left: 47%;
  width: 80px !important;
  height: 80px !important;
}

.MuiCircularProgress-colorPrimary {
  color: #0099ff;
}
/* End Styles for Modal Which opens end Interview spinner */

/* Start--Styles for Drawer which appears on smaller screens in editor page */
.MuiButton-root.header__loginButton {
  text-transform: none;
  font-family: CircularStd, Poppins, sans-serif;
  font-weight: 300 !important;
  padding: 0.45em 0.9em;
  background: #2563eb;
  color: white;
  width: 9.6em;
  margin: 0.2em;
}

.MuiDrawer-root.header__actionButtonsDrawer {
  z-index: 1200 !important;
}

.MuiPaper-root {
  background: #333333 !important;
  padding: 2em 2%;

  .actionButtonsDrawer__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.85em 0;
    min-width: 240px;
    flex-wrap: wrap;

    & > * {
      margin: 0.5em 0.65em;
    }

    .MuiButton-root.header__copyButton {
      text-transform: none;
      font-family: CircularStd, Poppins, sans-serif;
      font-weight: 300 !important;
      padding: 0.45em 0.9em;
      background: #0099ff;
      color: #fff;
      width: fit-content;

      .MuiSvgIcon-root {
        font-size: 1.2em !important;
        margin-right: 0.4em;
      }
    }

    .MuiButton-root.header__endButton {
      text-transform: none;
      font-family: CircularStd, Poppins, sans-serif;
      font-weight: 300 !important;
      padding: 0.45em 0.9em;
      background: transparent;
      color: #eb5757;
      border: 1px solid #eb5757;
      width: 9.6em;
    }
  }
}
/* End--Styles for Drawer which appears on smaller screens in editor page */

@media screen and (max-width: 426px) {
  .app {
    padding: 0 1vw;
  }
}
