.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #fff;

  .home__headerSideBlock {
    display: flex;
    align-items: center;

    .home__startInterviewButton {
      &.MuiButton-root {
        width: fit-content;
        font-family: CircularStd, Poppins, sans-serif;
        font-weight: 400;
        font-size: 0.9em;
        text-transform: none;
        color: #fff;
        padding: 0.7em 1.4em;
        border-radius: 12px;
        background: linear-gradient(90deg, #2563eb 1.78%, #0099ff 100%),
          linear-gradient(0deg, #ffffff, #ffffff);
      }
    }

    .userAvatar__avatarContainer {
      margin-left: 2em;
    }
  }

  .home__previewsWrapper {
    padding: 4em 8vw;

    & > .home__previewsContainer:not(:last-child) {
      margin-bottom: 4em;
    }

    .home__previewsContainer {
      .previewsContainer__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2em;

        .previewsContainer__title {
          font-size: 1.2em;
          font-weight: 500;
          word-break: break-all;
          overflow: hidden;
        }

        .previewsContainer__expandButton {
          font-size: 1em;
          font-weight: 300;
          cursor: pointer;
        }
      }

      .previewsContainer__previews {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & > .interviewPreview {
          margin: 1em;
        }
      }

      .previewsContainer__noPreviews {
        width: 100%;
        font-size: 1em;
        color: rgba(255, 255, 255, 0.55);
        text-align: center;
        padding: 2.4em;
        background: #23262b;
        border-radius: 15px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .home {
      .home__previewsWrapper {
        .home__previewsContainer {
          .previewsContainer__previews {
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
