.interviewPreview {
  width: 260px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #23262b;
  border-radius: 1em;
  color: #fff;
  overflow: hidden;
  cursor: pointer;

  .interviewPreview__previewImage {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .previewImage__noImage {
      width: 100%;
      height: 50%;
      opacity: 0.5;
    }
  }

  .interviewPreview__details {
    background: #2e3238;
    padding: 1.2em 1.4em;

    .interviewPreview__title {
      font-size: 1.2em;
    }

    .interviewPreview__extraInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.74em;
      margin-top: 1.2em;
      color: rgba(255, 255, 255, 0.8);

      .extraInfo__owner {
        width: 55%;
        overflow: hidden;
      }

      .extraInfo__date {
        width: 42%;
        overflow: hidden;
        text-align: right;
      }
    }
  }
}
