.infoModal {
  outline: none;
  position: absolute;
  width: fit-content;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #333333;
  color: #fff;
  border-radius: 10px;
  padding: 3.4em 3.2em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: CircularStd, Poppins, sans-serif;

  & > div,
  span {
    width: 100%;
  }

  .infoModal__heading {
    font-size: 1.8em;
    opacity: 0.9;
    padding: 0 0.2em;
    margin-bottom: 1em;
  }

  .infoModal__subHeading {
    font-size: 1em;
    opacity: 0.6;
    padding: 0 0.5em;
    margin-bottom: 1.4em;
  }

  .infoModal__documentInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: #272626;
    border-radius: 10px;
    padding: 1em 1.4em;
    font-size: 15px;

    & > div:first-child {
      margin-bottom: 2em;
    }

    .documentInfo__link {
      color: #99e3fb;
      margin-top: 0.6em;
      word-break: break-all;
      cursor: pointer;
    }

    .documentInfo__link:hover {
      text-decoration: underline;
    }

    .documentInfo__link--spreadOut {
      letter-spacing: 2px;
      font-size: 15px;
    }

    .MuiIconButton-root {
      position: absolute;
      bottom: 2%;
      right: 1%;
      font-size: 12px;
      color: #fff;
      border-radius: 0;
      font-family: CircularStd, Poppins, sans-serif;
      background: transparent;
      box-shadow: none;

      .MuiSvgIcon-root {
        font-size: 18px;
        margin-right: 8px;

        &.copyDocumentInfo__copyIcon {
          transform: scaleY(1.4);
        }
      }
    }

    // Close Button
    .infoModal__closeButton {
      position: absolute;
      top: -2%;
      right: -9%;
      z-index: 10;

      .MuiIconButton-root {
        padding: 5px;
        background: rgba(255, 255, 255, 0.065);
        border-radius: 50%;

        .MuiSvgIcon-root {
          font-size: 24px;
          margin: 0;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }

    // "Copied" text
    .infoModal__copiedAlert {
      position: absolute;
      width: fit-content;
      border-radius: 5px;
      z-index: 10;
      bottom: 32%;
      right: 1%;
      background: rgba(0, 0, 0, 0.8);
      color: #fff;
      font-size: 12px;
      padding: 6px 10px;
      transition: transform 0.2s ease;

      &.infoModal__copiedAlert--open {
        transform: translateY(0);
        opacity: 1;
        animation: fadeIn 0.2s ease;
      }

      &.infoModal__copiedAlert--closed {
        transform: translateY(60%);
        opacity: 0;
        pointer-events: none;
        animation: fadeOut 0.2s ease;
      }
    }
  }

  .infoModal__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;

    .infoModal__exitButtons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2em;

      & > * {
        width: 46% !important;
      }
    }

    .MuiButton-root {
      width: 40%;
      font-family: CircularStd, Poppins, sans-serif;
      background: transparent;
      border: 2px solid #fff;
      padding: 0.65em 4em;
      text-transform: inherit;
      color: #fff;
      font-weight: 400;

      &.infoModal__copyButton {
        width: 100%;
        font-size: 18px;
        opacity: 0.8;
      }

      &.infoModal__yesButton {
        color: #45adff;
        border-color: #45adff;
      }

      &.infoModal__endButton {
        color: #eb5757;
        border-color: #eb5757;
      }
    }
  }

  @media screen and (min-width: 1640px) {
    & {
      max-width: 680px;
      padding: 3.8em 3.6em;

      .infoModal__heading {
        font-size: 2.2em;
      }

      .infoModal__subHeading {
        font-size: 1.4em;
      }

      .infoModal__documentInfo {
        font-size: 1.4em;
      }

      .infoModal__buttons {
        .MuiButton-root {
          font-size: 1.4em !important;
        }
      }
    }
  }

  @media screen and (max-width: 570px) {
    & {
      max-width: 92vw;
      padding: 2.6em 1.4em;
      padding-top: 3.2em;

      .infoModal__documentInfo .infoModal__closeButton {
        top: -6%;
        right: -4.5%;

        .MuiIconButton-root {
          padding: 4px;

          .MuiSvgIcon-root {
            font-size: 22px;
          }
        }
      }

      .infoModal__buttons .MuiButton-root.infoModal__copyButton {
        font-size: 14px;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      transform: translateY(60%);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      transform: translateY(60%);
      opacity: 0;
    }
  }
}
