.codeEditor__container {
  position: relative;
  border-radius: 5px;
  border-top: 36px solid transparent;

  .codeEditor__heading {
    position: absolute;
    top: -4.8%;
    color: rgba(255, 255, 255, 0.85);
    font-size: 17px;
    font-weight: 500;
    z-index: 10;
  }

  .codeEditor__options {
    cursor: pointer;
    position: absolute;
    top: 1%;
    right: 0;
    background: #272822;
    border: 0;
    border-radius: 8px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.85);
    outline: none;
    padding: 8px 10px;
    text-transform: capitalize;
    text-decoration: none;
    font-family: CircularStd, Poppins, sans-serif;
    z-index: 10;
  }

  .codeEditor {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 1560px) {
    & {
      .codeEditor__heading {
        font-size: 22px;
      }

      .codeEditor__options {
        font-size: 15px;
        padding: 9px 12px;
      }
    }
  }

  @media screen and (max-width: 1350px) {
    .codeEditor__options {
      top: 1.65%;
    }
  }

  @media screen and (max-width: 768px) {
    .codeEditor__options {
      top: 7.5%;
    }
  }
}
