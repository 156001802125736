.preplacedDefaultHeader__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.8em 8vw;

  .header__loginButton {
    background: #2563eb;
    color: white;
    border-radius: 4px;
    border: 0;
    font-size: 0.9rem;
    font-weight: 300;
    font-family: CircularStd, Poppins, sans-serif;
    padding: 0.7em 0em;
    width: 9em;
    cursor: pointer;
  }

  @media screen and (min-width: 1560px) {
    & {
      padding: 2.4em 8vw;
      padding-bottom: 1em;

      .MuiButton-root {
        font-size: 1.3em;

        .MuiSvgIcon-root {
          font-size: 36px;
        }
      }
    }
  }

  @media screen and (max-width: 1560px) {
    & {
      padding: 1.8em 8vw;
      padding-bottom: 1em;

      .MuiButton-root {
        font-size: 0.85em;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    & {
      .MuiButton-root {
        font-size: 0.7em;
      }
    }
  }

  @media screen and (max-width: 426px) {
    & {
      padding: 2em 3vw;

      .MuiButton-root {
        font-size: 0.65em;

        .MuiSvgIcon-root {
          font-size: 20px;
        }
      }

      .header__loginButton {
        font-size: 0.75rem;
        padding: 0.6em 0em;
        width: 8em;
      }
    }
  }
}
