.pageNotFound {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../../assets/not-found-background-pattern.svg");
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0 8vw;
  text-align: center;

  .pageNotFound__heading {
    font-size: 2.6em;
    color: #fff;
  }

  .pageNotFound__message {
    margin-top: 1.8em;
    font-size: 1.3em;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
  }

  .pageNotFound__goBackHome {
    outline: none;
    margin-top: 2.4em;
    padding: 1em 2em;
    color: #ffffff;
    background: #0099ff;
    font-size: 1em;
    text-decoration: none;
    border-radius: 2px;
  }

  @media screen and (min-width: 1640px) {
    & {
      transform: scale(1.4);
    }
  }

  @media screen and (max-width: 768px) {
    & {
      .pageNotFound__heading {
        font-size: 2em;
      }

      .pageNotFound__message {
        font-size: 1em;
      }

      .pageNotFound__goBackHome {
        font-size: 0.85em;
      }
    }
  }
}
