.previewCardSkeleton {
  width: 260px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #23262b;
  border-radius: 1em;
  margin: 0.5em;
  overflow: hidden;

  .MuiSkeleton-root {
    background: rgba(255, 255, 255, 0.08);
    transform: scale(1, 1);
    border-radius: 0;
  }

  .previewCardSkeleton__bottom {
    width: 100%;
    height: 80px;
    background: rgba(46, 50, 56, 0.6);
  }
}
