.authCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 460px;
  transform: scale(0.9);
  color: #fff;
  background: #272822;
  padding: 2.4em 2.6em;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);

 
  .authCard__closeButton {
    position: absolute;
    top: 3%;
    right: 5%;
    padding: 5px;
    background: rgba(255, 255, 255, 0.1);

    .MuiSvgIcon-root {
      color: #fff;
    }
  }

  .authCard__heading {
    font-size: 26px;
    text-align: center;
    margin-bottom: 0.5em;
    color: rgba(255, 255, 255, 0.9);
  }

  form {
    display: flex;
    flex-direction: column;

    input {
      width: 100%;
      border: none;
      outline: none;
      background: #171717;
      font-family: CircularStd, Poppins, sans-serif;
      font-size: 1em;
      color: rgba(255, 255, 255, 0.9);
      padding: 1.2em 1.4em;
      margin: 0.6em 0;
      border-radius: 8px;

      &[type="password"] {
        letter-spacing: 12px;
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.45);
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.5px;
      }
    }

    .createAccountButton {
      margin-top: 0.85em;
    }

    .forgotPasswordButton__link {
      text-decoration: none;
    }

    .forgotPasswordButton {
      margin-top: 0.85em;
    }
  }

  .otherMethodSignIn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75em;
    padding-top: 1em;
    border-top: 0.7px solid rgba(255, 255, 255, 0.075);

    .MuiButton-root {
      margin: 0.5em 0;
    }
  }

  .authCard__navigationMessage {
    width: 100%;
    margin-top: 2em;
    font-size: 0.9em;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;

    .nav__tab{
      margin: 0px 5px;
      width: auto;
      background-color:#0099ff ;
      color:rgba(255, 255, 255, 0.8);
    }
  }

  .MuiButton-root {
    width: 100%;
    color: #fff;
    text-transform: none;
    padding: 1em 2em !important;
    font-weight: 400;
    font-size: 1em;
    font-family: CircularStd, Poppins, sans-serif;

    img {
      width: 30px;
      margin-right: 0.8em;
    }

    &.createAccountButton {
      background: #ff4181;

      &:hover {
        background: #ff4181;
      }
    }

    &.forgotPasswordButton {
      background: transparent;
      transition: all 0.2s ease;

      &:hover {
        color: #0099ff;
        text-decoration: underline;
        background: transparent;
      }
    }

    &.googleSignInButton {
      color: #000000;
      font-weight: 500;
      background: #ffffff;

      &:hover {
        background: #ffffff;
      }
    }

    &.githubSignInButton {
      background: #000000;

      &:hover {
        background: #000000;
      }
    }
  }

  @media screen and (max-width: 2560px) and (min-width: 1640px) {
    & {
      transform: scale(0.9);
    }
  }

  @media screen and (max-width: 1440px) {
    & {
      width: 420px;

      .authCard__heading {
        font-size: 24px;
      }

      form input {
        padding: 1em 1.4em;
      }

      .MuiButton-root {
        padding: 0.8em 1.65em !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    & {
      width: 380px;
      padding: 2.4em;

      .authCard__heading {
        font-size: 24px;
      }

      form input {
        padding: 1em 1.4em;
      }

      .MuiButton-root {
        font-size: 0.95em;
        padding: 0.6em 1.65em !important;
      }

      .authCard__navigationMessage {
        font-size: 0.8em;
      }
    }
  }

  @media screen and (max-width: 426px) {
    & {
      width: 350px;
      padding: 2.4em 2em;

      form input {
        padding: 0.8em 1.4em;
      }

      .MuiButton-root {
        font-size: 0.9em;
        padding: 0.5em 1.65em !important;

        img {
          width: 28px;
        }
      }
    }
  }

  @media screen and (max-width: 320px) {
    & {
      width: 310px;
      padding: 2.2em 1em;

      .authCard__heading {
        font-size: 20px;
      }

      form input {
        font-size: 0.8em;
        padding: 1em 1.4em;

        &::placeholder {
          font-size: 1em;
        }
      }

      .MuiButton-root {
        font-size: 0.8em;
        padding: 0.4em 1em !important;

        img {
          width: 26px;
        }
      }
    }
  }
}
