.textEditor {
  position: relative;
  border-radius: 5px;
  border-top: 36px solid transparent;
  resize: horizontal;

  .textEditor__heading {
    position: absolute;
    top: -30px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 17px;
    font-weight: 500;
    z-index: 10;
  }

  @media screen and (min-width: 1560px) {
    .textEditor__heading {
      font-size: 22px;
    }
  }
}
