.userAvatar__avatarContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .avatarContainer__avatar {
    cursor: pointer;

    .MuiAvatar-root {
      width: 45px;
      height: 45px;
      border: 2px solid #fff;
    }
  }

  .userAvatar__userOptionsDropdown {
    position: absolute;
    top: 150%;
    left: -100%;
    opacity: 0;
    pointer-events: none;
    background: #2e3238;
    border-radius: 15px;
    padding: 1.2em 0.8em;
    z-index: 20;
    transition: all 0.2s ease;

    &.userAvatar__userOptionsDropdown--open {
      opacity: 1;
      pointer-events: all;
    }

    .MuiMenuItem-root {
      font-family: CircularStd, sans-serif;
      font-size: 1em;
      margin: 0.4em 0;

      &.userOptionsDropdown__logout {
        color: #ef4444;

        .MuiListItemIcon-root {
          .MuiSvgIcon-root {
            color: #ef4444;
          }
        }
      }

      .MuiListItemIcon-root {
        min-width: fit-content;
        margin-right: 1em;

        .MuiSvgIcon-root {
          font-size: 1.4em;
          color: #fff;
        }
      }
    }
  }
}
